import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { GridContainer, GridItem } from '@entur/grid';
import { colors } from '@entur/tokens';
import { StrongText, Paragraph } from '@entur/typography';
import bilde1 from './foto/bilde1.jpg';
import bilde2 from './foto/bilde2.jpg';
import bilde3 from './foto/bilde3.jpg';
import bilde4 from './foto/bilde4.jpg';
import PageHeader from '~/components/PageHeader';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader"></PageHeader>
    <img src={bilde1} style={{
      width: '100%'
    }} alt="Buss som kjører over vinterlandskap" />
    <h2 {...{
      "id": "eksempler-på-motiver"
    }}>{`Eksempler på motiver`}</h2>
    <h3 {...{
      "id": "tilbyderne-og-transportmidlene"
    }}>{`Tilbyderne og transportmidlene`}</h3>
    <p>{`For eksempel: Infrastrukturen - Stasjoner og holdeplasser - Detaljer fra transportmidlene - Nettverket - Trafikk`}</p>
    <GridContainer spacing="large" mdxType="GridContainer">
  <GridItem small={6} medium={4} mdxType="GridItem">
    <img src={bilde2} style={{
          width: '100%'
        }} alt="Person med ryggsekk på perongen ved siden av et tog" />
  </GridItem>
  <GridItem small={6} medium={4} mdxType="GridItem">
    <img src={bilde3} style={{
          width: '100%'
        }} alt="Tog som  kjører i vinterlandskap" />
  </GridItem>
  <GridItem small={6} medium={4} mdxType="GridItem">
    <img src={bilde4} style={{
          width: '100%'
        }} alt="Veikryss som viser til trafikk og bevegelser" />
  </GridItem>
    </GridContainer>
    <h2 {...{
      "id": "prinsipper"
    }}>{`Prinsipper`}</h2>
    <h3 {...{
      "id": "vi-kommer-lenger-sammen"
    }}>{`Vi kommer lenger sammen.`}</h3>
    <p>{`Vi synliggjør mulighetene og potensialet i et samlet kollektiv-Norge, og verdien det har for de reisende.
Vi løfter frem mangfoldigheten i infrastrukturen og landet vårt, og alle de ulike turene det inviterer til.
Vi spiller på kontraster; fra nord til sør, det store toget og den lille lokale fergen.`}</p>
    <h3 {...{
      "id": "den-røde-tråden"
    }}>{`Den røde tråden`}</h3>
    <p>{`Vi bygger identitet i bildene, ofte med motiver med tydelige horisontale linjer.
Linjene går til kant; de starter og stopper utenfor rammen.
Det gir bildene et dynamisk uttrykk og kobler dem til en større sammenheng.
Det grafiske virkemiddelet skaper et rent uttrykk, strammer og skaper ro i komposisjonen.
Det gir også muligheten til å binde flere foto sammen med en, uavbrutt linje eller horisont.
Det er ikke i alle foto at dette grepet er mulig. Vi forsøker i det lengste å binde disse fotoene sammen med den fine, røde tråden.
Reisen som går uavbrutt gjennom motivene.`}</p>
    <h3 {...{
      "id": "lett-og-leken"
    }}>{`Lett og leken`}</h3>
    <p>{`Bildene uttrykker en følelse av mestring hos brukerne, og viser en sømløs integrering av teknologi i hverdagen.
Bildene uttrykker liv og bevegelse og har en positiv stemning.
Vi viser mennesker i bevegelse, og teknologien i bruk.`}</p>
    <h2 {...{
      "id": "stil-og-tone"
    }}>{`Stil og tone`}</h2>
    <div style={{
      display: 'grid',
      gap: '2rem',
      gridTemplateColumns: '1fr'
    }}>
  <div className="card" style={{
        padding: '1.5rem'
      }}>
    <StrongText mdxType="StrongText">Naturlig lys</StrongText>
    <Paragraph margin="none" mdxType="Paragraph">
      Bildene har et lett, nordisk uttrykk med mye blåstikk. Foto tas i naturlig
      lys. Ta gjerne bilder utendørs.
    </Paragraph>
  </div>
  <div className="card" style={{
        padding: '1.5rem'
      }}>
    <StrongText mdxType="StrongText">Ekte farger</StrongText>
    <Paragraph margin="none" mdxType="Paragraph">
      Fargene er naturlige og avspeiler virkeligheten. God kontrast underbygger
      det grafiske uttrykket.
    </Paragraph>
  </div>
  <div className="card" style={{
        padding: '1.5rem'
      }}>
    <StrongText mdxType="StrongText">I bevegelse</StrongText>
    <Paragraph margin="none" mdxType="Paragraph">
      Vi skaper et livlig uttrykk ved å fokusere på personer i bevegelse eller
      transportmidler og teknologi i bruk.
    </Paragraph>
  </div>
  <div className="card" style={{
        padding: '1.5rem'
      }}>
    <StrongText mdxType="StrongText">Rett på</StrongText>
    <Paragraph margin="none" mdxType="Paragraph">
      Vi forsøker å være rett på der det er mulig. Ved å ta bildene rett på
      skaper vi et rent, grafisk og ærlig uttrykk.
    </Paragraph>
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      